<template>
  <div class="orderProcesses">
    <div class="orderTabs">
      <Tabs :tabs="tabs" :active-tab.sync="activeTab" @click="onActiveTabChanged" />
    </div>
    <div class="orderTables">
      <ActiveOrderProcessesTable
        v-if="activeTab === TABS.ACTIVE"
        :loading="activeOrdersLoading"
        :supplier-order-terms="supplierOrderTerms"
        :supplier-order-terms-loading="supplierOrderTermsLoading"
        :delete-loading="deleteLoading"
        :is-delete-modal-open="isDeleteModalOpen"
        :open-orders="data"
        :limit="LIMIT"
        :page="page"
        :total="total"
        @on-toggle-delete-modal="(openState) => (isDeleteModalOpen = openState)"
        @on-page-changed="onPageChanged"
        @on-delete-order="onDeleteOrder"
      />
      <ClosedOrderProcessesTable
        v-if="activeTab === TABS.CLOSED"
        :loading="closedOrdersLoading"
        :closed-orders="data"
        :limit="LIMIT"
        :page="page"
        :total="total"
        @on-page-changed="onPageChanged"
      />
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue';
import { Tabs } from '@/modules/core/components';
import ClosedOrderProcessesTable from './ClosedOrderProcessesTable';
import ActiveOrderProcessesTable, { ORDER_STATUSES } from './ActiveOrderProcessesTable';
import { useOrderProcesses } from '../compositions/useOrderProcesses';
import { useDeleteOrderProcess } from '../compositions/useDeleteOrderProcess';
import { useTenancy } from '@/modules/auth';
import { useAnalytics } from '../../../analytics/compositions/useAnalytics';
import { PUR_ANALYTICS_EVENT_TYPES } from '@/analytics/types/pur-analytics-types';

const TABS = {
  ACTIVE: 0,
  CLOSED: 1,
};

const LIMIT = 25;
const MINUTES_TO_REFETCH_ACTIVE_ORDERS = 5;
export default {
  components: { ClosedOrderProcessesTable, ActiveOrderProcessesTable, Tabs },
  props: {
    supplierOrderTerms: { type: Array, required: true },
    supplierOrderTermsLoading: { type: Boolean, required: true },
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const page = ref(1);
    const activeTab = ref(0);
    const { currentTenant } = useTenancy();
    const isDeleteModalOpen = ref(false);
    const { logEvent } = useAnalytics();

    const first = LIMIT;
    const after = computed(() => LIMIT * (page.value - 1));

    const {
      orderProcesses: activeOrderProcesses,
      loading: activeOrdersLoading,
      refetch: refetchActiveOrders,
    } = useOrderProcesses({
      currentTenant,
      finalized: false,
      first,
      after,
      activeTab,
      valueToCheckActiveTab: TABS.ACTIVE,
    });

    const { orderProcesses: closedOrderProcesses, loading: closedOrdersLoading } = useOrderProcesses({
      currentTenant,
      finalized: true,
      first,
      after,
      activeTab,
      valueToCheckActiveTab: TABS.CLOSED,
    });
    const { deleteOrderProcess, onDone: onDeleteDone, loading: deleteLoading } = useDeleteOrderProcess();

    const total = computed(() => {
      if (activeTab.value === TABS.ACTIVE) return activeOrderProcesses.value.totalCount;
      return closedOrderProcesses.value.totalCount;
    });

    const tabs = computed(() => {
      const badge = activeOrdersLoading.value === true ? {} : { badgeValue: activeOrderProcesses.value.totalCount };
      return [{ text: root.$t('orders.openOrders'), ...badge }, { text: root.$t('orders.closedOrders') }];
    });

    const data = computed(() => {
      if (activeTab.value === TABS.ACTIVE) {
        return activeOrderProcesses.value.nodes.map((orderProcess) => {
          const orderProcessToUpdate = { ...orderProcess };
          if (orderProcess.dispatches && orderProcess.dispatches.length > 0) {
            let isSending = true;
            let isFailed = true;
            let hasRetry = false;
            orderProcess.dispatches.forEach(({ status }) => {
              if (status === ORDER_STATUSES.RETRY) {
                hasRetry = true;
              }
              if (status !== ORDER_STATUSES.SENDING && status !== ORDER_STATUSES.SENT_TO_CHANNEL) {
                isSending = false;
              }
              if (status !== ORDER_STATUSES.FAILED) {
                isFailed = false;
              }
            });
            if (isSending || hasRetry) {
              orderProcessToUpdate.sentStatus = ORDER_STATUSES.SENDING;
            } else if (isFailed) {
              orderProcessToUpdate.sentStatus = ORDER_STATUSES.FAILED;
            } else {
              orderProcessToUpdate.sentStatus = ORDER_STATUSES.PARTLY_SENT;
            }
          } else {
            orderProcessToUpdate.sentStatus = ORDER_STATUSES.NOT_SENT;
          }

          return orderProcessToUpdate;
        });
      }
      return closedOrderProcesses.value.nodes;
    });

    const onActiveTabChanged = () => {
      page.value = 1;
    };

    const onPageChanged = ({ page: newPage }) => {
      page.value = newPage;
    };

    const onDeleteOrder = ({ orderIdToDelete, cartEmpty }) => {
      logEvent(PUR_ANALYTICS_EVENT_TYPES.ORDER_PROCESS.DELETE, {
        orderId: orderIdToDelete,
        'cart empty': cartEmpty,
        from: 'table',
      });
      deleteOrderProcess({ orderId: orderIdToDelete });
    };

    onDeleteDone(() => {
      isDeleteModalOpen.value = false;
      root.$message.success(root.$t('orders.table.deleteActiveOrder.success'));
      refetchActiveOrders();
    });

    let refetchActiveOrdersIntervalId = null;
    onMounted(() => {
      refetchActiveOrdersIntervalId = setInterval(refetchActiveOrders, MINUTES_TO_REFETCH_ACTIVE_ORDERS * 60 * 1000);
    });

    onBeforeUnmount(() => clearInterval(refetchActiveOrdersIntervalId));

    return {
      TABS,
      LIMIT,
      page,
      tabs,
      data,
      total,
      activeTab,
      deleteLoading,
      isDeleteModalOpen,
      activeOrdersLoading,
      closedOrdersLoading,
      onPageChanged,
      onDeleteOrder,
      onActiveTabChanged,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.orderProcesses {
  display: flex;
  height: 100%;
  gap: 2rem;
  flex-direction: column;
}
</style>
