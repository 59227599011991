<template>
  <el-dialog :show-close="false" :visible="show" custom-class="new-order-wrapper rounded" @close="$emit('close')">
    <template #title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>{{ $t('orders.createOrder') }}</h2>

        <Button type="text" class="p-0 text-typography-primary close-btn" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <SearchBar
      class="w-100 mb-6"
      :search-value="searchValue"
      :placeholder="$t('orders.searchBar')"
      @onClear="() => (searchValue = '')"
      @onChange="(value) => (searchValue = value)"
    />
    <div class="table-wrapper">
      <Table v-loading="loading" :data="tableData" :columns="tableColumns" :show-index="1" border rounded>
        <template #cell-latestOrder="{ rowData: { latestOrder, openOrdersLength, latestOrderEpoch } }">
          <span v-if="latestOrder && formatCurrentDateTimeWithWeekday(latestOrder)">
            <el-tooltip placement="top" :content="`${formatCurrentDateTimeWithWeekday(latestOrder)}`">
              <span class="date-with-time-content">{{ formatDuration(latestOrderEpoch) }}</span>
            </el-tooltip>
          </span>
          <span v-else> - </span>
          <span v-if="openOrdersLength" class="tag">
            {{
              $t(openOrdersLength === 1 ? 'orders.openOrderLength' : 'orders.openOrdersLength', { openOrdersLength })
            }}
          </span>
        </template>
        <template #cell-deliveryArrivalTime="{ rowData: { id } }">
          <DeliveryArrivalTimeCell :supplier-id="id" :all-terms="supplierOrderTerms" />
        </template>
        <template #cell-orderCutOffTime="{ rowData: { id } }">
          <OrderCutOffTimeCell
            :supplier-id="id"
            :all-terms="supplierOrderTerms"
            @on-order-cut-off-change="onOrderCutOffChange"
          />
        </template>
        <template #cell-actions="{ rowData }">
          <Button class="actions-btn" size="small" @click="createOrder(rowData)">{{ $t('orders.startOrder') }}</Button>
        </template>
      </Table>
    </div>
  </el-dialog>
</template>
<script>
import { computed, ref, getCurrentInstance } from 'vue';

import { CloseIcon } from '@/assets/icons';

import { SearchBar, Table, Button } from '@/modules/core';
import { useTenancy } from '@/modules/auth';

import { useOrderAggregationBySupplier } from '@/modules/purchase-management/compositions/order/useOrderAggregationBySupplier';

import { useOrderProcesses } from '../compositions/useOrderProcesses';
import { useCreateOrderProcess } from '../compositions/createOrderProcess';
import {
  formatCurrentDateTimeWithWeekday,
  formatDuration,
  returnDashInsteadOfNull,
  getISODateFromDate,
} from '../../purchase-management/purchaseManagementFormatters';

import {
  getClosestOrderTermCutOffDate,
  getDeliveryArrivalAndCutOffDates,
} from '../../purchase-management/purchaseManagement';

import { tableColumns } from './newOrderProcessColumns';

import OrderCutOffTimeCell from './cellRenderers/OrderCutOffTimeCell.vue';
import DeliveryArrivalTimeCell from './cellRenderers/DeliveryArrivalDateCell.vue';
import { useAnalytics } from '../../../analytics/compositions/useAnalytics';
import { PUR_ANALYTICS_EVENT_TYPES } from '@/analytics/types/pur-analytics-types';

export default {
  components: {
    SearchBar,
    Table,
    Button,
    CloseIcon,
    DeliveryArrivalTimeCell,
    OrderCutOffTimeCell,
  },
  props: {
    show: { type: Boolean, default: false },
    supplierOrderTerms: { type: Array, required: true },
    supplierOrderTermsLoading: { type: Boolean, required: true },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const RESULTS_AMOUNT = 25;
    const today = new Date();
    const lastYear = new Date(new Date().setFullYear(today.getFullYear() - 1));
    const reactiveSupplierListWithClosestOrderCutOffTime = ref(undefined);
    const { logEvent } = useAnalytics();

    const { currentTenant } = useTenancy();
    const { createOrderProcess, onDone, loading } = useCreateOrderProcess();

    const { groupBySupplier } = useOrderAggregationBySupplier({ value: currentTenant.value.id }, null, {
      value: {
        fromDate: getISODateFromDate(lastYear),
        toDate: getISODateFromDate(today),
      },
    });

    const { orderProcesses: openOrders } = useOrderProcesses({
      currentTenant,
      finalized: false,
      first: RESULTS_AMOUNT,
      after: ref(0),
      activeTab: ref(0),
      valueToCheckActiveTab: 0,
    });

    const searchValue = ref('');

    const supplierListWithClosestOrderCutOffTime = computed(() => {
      return props.supplierOrderTerms.map((orderTerm) => {
        const closestOrderCutOffDate = getClosestOrderTermCutOffDate(orderTerm.supplier.id, props.supplierOrderTerms);
        return { ...orderTerm.supplier, closestOrderCutOffDate };
      });
    });
    const sortedOpenOrders = computed(() => [...openOrders.value.nodes].sort((a, b) => b.openedAt - a.openedAt));

    const onOrderCutOffChange = () => {
      const relevantSupplierList =
        reactiveSupplierListWithClosestOrderCutOffTime.value ?? supplierListWithClosestOrderCutOffTime.value;

      reactiveSupplierListWithClosestOrderCutOffTime.value = relevantSupplierList.map((supplier) => {
        const closestOrderCutOffDate = getClosestOrderTermCutOffDate(supplier.id, props.supplierOrderTerms);
        return { ...supplier, closestOrderCutOffDate };
      });
    };

    const sortedPermittedSuppliers = computed(() => {
      const relevantSupplierList =
        reactiveSupplierListWithClosestOrderCutOffTime.value ?? supplierListWithClosestOrderCutOffTime.value;

      return relevantSupplierList
        .filter((supplier) => supplier.name.toLowerCase().includes(searchValue.value))
        .map((supplier) => {
          const openSupplierOrders = sortedOpenOrders.value.filter((order) => order.supplier.id === supplier.id);
          const latestOrder = groupBySupplier.value.find(
            (supplierWithOrder) => supplierWithOrder.supplierId === supplier.id
          );

          return {
            ...supplier,
            latestOrder: latestOrder?.lastOrder?.date,
            latestOrderEpoch: latestOrder?.lastOrder?.date.getTime(),
            openOrdersLength: openSupplierOrders.length,
          };
        })
        .sort((supplier1, supplier2) => {
          if (supplier1.closestOrderCutOffDate === null) return 1;
          return supplier1.closestOrderCutOffDate - supplier2.closestOrderCutOffDate;
        });
    });

    const createOrder = (supplier) => {
      const relevantSupplierSchedule = props.supplierOrderTerms.find(
        (orderTerm) => orderTerm.supplier.id === supplier.id
      );
      const closestArrivalDates = getDeliveryArrivalAndCutOffDates(relevantSupplierSchedule).arrivalDates;

      createOrderProcess({
        supplierId: supplier.id,
        customerId: currentTenant.value.id,
        arrivesAt: closestArrivalDates[0],
      });
    };

    onDone((response) => {
      logEvent(PUR_ANALYTICS_EVENT_TYPES.ORDER_PROCESS.CREATE, {
        orderId: response?.data?.orderProcess?.id,
        supplierId: response?.data?.orderProcess?.supplierId,
        supplierName: response?.data?.orderProcess?.supplier?.name,
        businessId: currentTenant.value.id,
        businessName: currentTenant.value.name,
      });

      root.$router.push({
        name: 'orderItems',
        params: {
          orderId: response?.data?.orderProcess?.id,
        },
      });
    });

    return {
      searchValue,
      tableData: sortedPermittedSuppliers,
      tableColumns: tableColumns(),
      onOrderCutOffChange,
      formatCurrentDateTimeWithWeekday: (date) => formatCurrentDateTimeWithWeekday(date),
      formatDuration: (date) => returnDashInsteadOfNull(formatDuration(date)),
      createOrder,
      loading,
    };
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';

::v-deep {
  .new-order-wrapper {
    width: 95vw;
    max-width: 1216px;
    height: 70vh;
    margin-top: 15vh !important;
    margin-bottom: 15vh !important;

    .el-dialog__body {
      height: 87%;
      overflow: hidden;
    }

    tr {
      td {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
      }
      &:hover {
        .actions-btn {
          opacity: 100;
        }

        td {
          cursor: default !important;
        }
      }
    }
  }
}

.tag {
  border-radius: 0.1875rem;
  border: 1px solid #d8e3e9;
  background: #f8fafb;
  font-size: 0.5625rem;
  padding: 4px;
  margin-right: 5px;
}

.table-wrapper {
  height: 100%;
  overflow: auto;
}

.actions-btn {
  opacity: 0;
}

.close-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}
</style>
