<template>
  <div>
    {{ duration }}
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';

import { formatDuration } from '../../purchase-management/purchaseManagementFormatters';

export default {
  name: 'Countdown',
  components: {},
  props: {
    date: { type: Date, required: true },
    onlyTime: { type: Boolean, default: false },
  },
  emits: ['on-duration-change'],
  setup(props, { emit }) {
    const duration = ref();

    const updateCurrentDate = () => {
      const currentTime = props.date.getTime();
      if (!duration.value || duration.value !== formatDuration(currentTime)) {
        duration.value = formatDuration(props.date.getTime());
        emit('on-duration-change');
      }
    };

    let updateCurrentDateId = null;
    onMounted(() => {
      updateCurrentDate();
      updateCurrentDateId = setInterval(updateCurrentDate, 1000);
    });

    onBeforeUnmount(() => clearInterval(updateCurrentDateId));

    const formattedDuration = computed(() =>
      props.onlyTime && duration.value
        ? duration.value.split(' ').slice(1, duration.value.length).join(' ')
        : duration.value
    );

    return {
      duration: formattedDuration,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
</style>
